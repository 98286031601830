<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.common.withdrawalApply')}}
    </navi-bar>
    <ul class="chargeTab">
       <li @click="goPageByName('coinExchange')">일반 출금</li>
       <li class="on" @click="goPageByName('exchange')">코인 출금</li>
    </ul>
    <template v-if="selectedCoin">
      <div class="selectWrap">
         <div class="selectBox mt30">
           <em>코인선택</em>
           <span class="name"><i></i></span>
           <select v-model="selectedCoin">
             <option :value="selectedCoin" >{{selectedCoin.coin}}</option>
           </select>
         </div>
         <input type="text" pattern="[0-9.,]+" class="numb mt30" :placeholder="$t('front.give.moneyInput')" v-model="getCurrentChargeMoney"/>
      </div>
      <div class="selectWrap">
         <div class="selectBox">
           <em>네트워크</em>
           <span class="name">{{selectedCoin.coin}}</span>
           <select v-model="selectedNetwork">
             <option v-for="(item, index) in networkList" :key="index" :value="item" >{{item.name}}</option>
           </select>
         </div>
         <a @click="getCalculate" class="btn">계산하기</a>
      </div>
      <div class="selectBox">
         <em>출금캐시</em>
         <p class="cash">
            <img src="@/assets/img/icon_coin.svg" />
            <span>{{thousand(cashCalculate)}}</span>
         </p>
      </div>

      <div class="address">
        <em>출금 지갑 주소</em>
        <input type="text" v-model="wallet.address" />
        <a><img src="@/assets_mobile/img/icon_copy.svg" /></a>
      </div>
      <ul class="applihave">
        <li><img src="@/assets_mobile/img/icon_moneys.svg" /></li>
        <li>출금 가능 잔액</li>
        <li><span>{{thousand(userData.cashAmt)}}</span></li>
      </ul>

      <div class="coinInfo">
        <ul>
          <li>
            <em>EXpected Arrival</em>
            <span>{{selectedNetwork.minConfirm}} network_confirmations</span>
          </li>
          <li>
            <em>EXpected Unlock</em>
            <span>{{selectedNetwork.unlockConfirm}} network_confirmations</span>
          </li>
          <li>
            <em>최소 출금 액수</em>
            <span>{{ thousand(selectedNetwork.withdrawMin) }}</span>
          </li>
          <li>
            <em>출금 수수료</em>
            <span>{{ thousand(selectedNetwork.withdrawFee) }}</span>
          </li>
        </ul>
      </div>

      <div class="btnwrap">
        <a class="ok" @click="onSubmit">{{thousand(cashCalculate)}} 캐시 {{$t('front.board.apply')}}</a>
      </div>
    </template>
    <template v-if="type == 'apply'">
      <div class="gamemoney">
         <div class="cashchange">
           <h2 class="name"><img src="@/assets_mobile/img/icon_gameMoney.svg" />{{$t('front.cash.haveGameCash')}}</h2>
           <div><span>{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span>{{$t('front.common.money')}}</div>
         </div>
         <a class="cashchangebtn" @click="moneyChange">{{$t('front.cash.cashChange')}}</a>
      </div>
      <div class="acountapply">
        <a @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_question.svg" /></a>
      </div>
      <div class="outmoney">
        <h2 class="conName">{{$t('front.board.withdrawMoney')}}</h2>
      </div>
      <div class="outmoneywrite"><input type="text" class="numb" pattern="[0-9.,]+" :placeholder="$t('front.give.moneyInput')" v-model="getCurrentChargeMoney"/><a>{{$t('front.give.allMoney')}}</a></div>
      <ul class="applihave">
        <li><img src="@/assets_mobile/img/icon_gameM.svg" /></li>
        <li>현재 보유머니</li>
        <li><span>72,705</span>원</li>
      </ul>
      <ul class="applimoneybtn">
        <li><a @click="setMoney(10000)">+1{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(50000)">+5{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(100000)">+10{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(1000000)">+100{{$t('front.cash.manwon')}}</a></li>
      </ul>
      <div class="outmoney">
        <h2 class="conName">{{$t('front.cash.withdrawPassword')}}</h2>
      </div>
      <div class="outmoneywrite"><input type="text" class="numb w100w" pattern="[0-9.,]+" :placeholder="$t('front.cash.inputPassword')" v-model="cashOutPass"/></div>
      <!--ul class="gamemoney">
        <li>{{$t('front.give.outCash')}}</li>
        <li><span class="or">{{thousand(userData.cashAmt)}}</span>{{$t('front.common.money')}}</li>
      </ul-->
      <div class="moneyinfowrap" v-if="moneyinfo">
        <div class="moneyinfoappli">
          <a class="close" @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
          <h3 class="applih">{{$t('front.cash.moneyOutInput')}}</h3>
          <ul class="applistep">
            <li><em>1</em><span>{{$t('front.cash.moneyCheckListN')}}</span></li>
            <li><em>2</em><span>{{$t('front.cash.moneyCheckListB')}}</span></li>
            <li><em>3</em><span>{{$t('front.cash.moneyCheckListD')}}</span></li>
          </ul>
        </div>

        <!--ul class="appliinput">
          <li>보유 캐시</li>
          <li class="or">{{thousand(userData.cashAmt)}}</li>
        </ul>
        <ul class="appliinput">
          <li>보유 게임머니</li>
          <li class="or">{{thousand(coinAmt ? coinAmt.toString(): '0')}}<a class="myinfochng">캐시전환</a></li>
        </ul>
        <ul class="appliinput">
          <li>출금금액 입력</li>
          <li><input type="text" class="numb" pattern="[0-9.,]+" placeholder="직접 입력시 숫자만 입력해 주세요." v-model="getCurrentChargeMoney"/></li>
        </ul>

        <ul class="moneybtnwrap">
          <li class="one"><a @click="setMoney(10000)">1만원</a></li>
          <li class="one"><a @click="setMoney(30000)">3만원</a></li>
          <li class="one"><a @click="setMoney(50000)">5만원</a></li>
          <li class="two"><a @click="setMoney(100000)">10만원</a></li>
        </ul>
        <ul class="moneybtnwrap">
          <li class="thr"><a @click="setMoney(300000)">30만원</a></li>
          <li class="four"><a @click="setMoney(500000)">50만원</a></li>
          <li class="fiv"><a @click="setMoney(1000000)">100만원</a></li>
          <li class="six"><a @click="setMoney(0)">정정</a></li>
        </ul>

        <ul class="appliinput">
          <li>출금 비밀번호</li>
          <li><input type="password" class="numb" placeholder="비밀번호를 입력하세요." v-model="cashOutPass" /></li>
        </ul-->
      </div>
      <!--div class="moneytab">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"><span>출금신청</span></a>
        <a :class="{'on' : type == 'list'}" @click="type = 'list'"><span>출금내역</span></a>
      </div-->
      <div class="btnwrap">
        <a class="ok" @click="onSubmit">{{$t('front.board.apply')}}</a>
      </div>
      <div class="applylistbtn pb100">
        <a :class="{'on' : type == 'list'}" @click="type = 'list'">{{$t('front.mypage.withdrawalList')}} ></a>
      </div>
    </template>

    <template v-if="type == 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />

      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <a class="close"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
            <!--input type="checkbox" v-model="item.checked"-->
            <ul class="gamebetdetail textleft">
              <li class="ft13">{{dateFormat(item.updDate)}}</li>
              <li class="ft15 mt10">{{$t('front.board.withdrawMoney')}}</li>
            </ul>
            <ul class="gamebetdetail textright">
              <li class="mt30 ft15"><span class="bl fb">{{thousand(item.cashAmt)}}</span> 원</li>
              <li class="gr ft13 mt10">{{dateFormat(item.updDate)}}</li>
            </ul>
            <!--a :class="computedCashStatus(item.cashStatus).class">{{computedCashStatus(item.cashStatus).text}}</a--><!-- class="done cancel" -->
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>
      </div>
      <!--div class="btnwrap">
        <a class="all" @click="onAllCheck('cashList')">{{allChecked ? '전체해제': '전체선택'}}</a>
        <a class="selec" @click="onRemoveMsg">선택삭제</a>
      </div-->
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
      <div class="applylistbtn left mt10 mb100">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'">&lt; {{$t('front.common.withdrawalApply')}}</a>
      </div>
    </template>

  </section>
</template>

<script>

import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Exchange from '@/views/member/money/exchange'

export default {
  name: 'exchange',
  data () {
    return {
      moneyinfo: false
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Exchange
  ]
}
</script>

<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style>
.morebtn {margin-bottom: 10px !important;}
</style>
